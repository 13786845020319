import React from 'react';
import Switch from "react-switch";
import { ESSEN, TRINKEN, FREIZEIT } from '../../utils/constants.js';
import { filter, saveFilter } from '../../utils/filter.js';
import IconFood from '../Icons/IconFood.js';
import IconDrink from '../Icons/IconDrink.js';
import IconFreetime from '../Icons/IconFreetime.js';
import SearchBox from './SearchBox.js';

export default class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValues: this.props.filterValues
    }
  }

  handleCategoryChange(category) {
    let filterValues = this.state.filterValues;
    if (filterValues.category.includes(category)) {
      filterValues.category.splice(filterValues.category.indexOf(category), 1);
    } else {
      filterValues.category.push(category);
    }

    this.setState({ filterValues }, () => {
      //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
      this.props.onChange(this.state.filterValues);
      saveFilter(this.state.filterValues);
    });
  }

  render() {
    return (
      <div>

        <button type="button" onClick={() => this.handleCategoryChange(ESSEN)} className={`btn btn-sm btn-filter mr-2 ${this.state.filterValues.category.includes(ESSEN) ? 'btn-filter-active' : ''} `}>
          Essen
          </button>
        <button type="button" onClick={() => this.handleCategoryChange(TRINKEN)} className={`btn btn-sm btn-filter mr-2 ${this.state.filterValues.category.includes(TRINKEN) ? 'btn-filter-active' : ''} `}>
          Trinken
              </button>
        <button type="button" onClick={() => this.handleCategoryChange(FREIZEIT)} className={`btn btn-sm btn-filter mr-2 ${this.state.filterValues.category.includes(FREIZEIT) ? 'btn-filter-active' : ''} `}>
          Freizeit
              </button>
      </div>

    );
  }
}