import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import makeApiCall from '../utils/makeApiCall.js';
import lokale from '../../__mock__/lokale_berlin.json';
import placedetails from '../../__mock__/placedetails.json';

class DataProcesser extends React.Component {

  addAdditionalPlaceInfo(lokal) {

  }

  async componentDidMount() {

    var processedLokale = [];


    
    Promise.all(
      lokale.map(lokal => addPlaceId(lokal))
    ).then(
      lokale => saveJson(lokale)
      //(lokaleWithPlaceIds) => lokaleWithPlaceIds.map(lokalWithPlaceIds => addPlaceDetails(lokalWithPlaceIds))
    );

    
    // saveJson(processedLokale);
  }

  render() {
    return (
      <a id="downloadAnchorElem">download</a>
    );
  }
}


function saveJson(storageObj) {
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storageObj));
  var dlAnchorElem = document.getElementById('downloadAnchorElem');
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", "scene.json");
  dlAnchorElem.click();
}

async function addPlaceId(lokal) {
  var query = lokal.title.replace("*", "");

  var queryResult = lokal.hasOwnProperty("osm_ids") ? await getPlaceDetails(lokal.osm_ids): await getSearchResult(query);
  lokal.placeDetails = queryResult;
  console.log(lokal);
  return lokal;


}

async function getSearchResult(query) {
  var city = "berlin", country ="de";
  let url = "https://nominatim.openstreetmap.org/search.php?countrycodes="+CustomElementRegistry+"&format=json&addressdetails=1&extratags=1&q=" + query + "%20" + city
  
  console.log( url);
  return await makeApiCall(url);

  // [{"place_id":61561709,"licence":"Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright","osm_type":"node","osm_id":5075059286,"boundingbox":["48.2167266","48.2168266","16.3599108","16.3600108"],"lat":"48.2167766","lon":"16.3599608","display_name":"Bagel Art 1683, Währinger Straße, KG Alsergrund, Alsergrund, Wien, 1090, Österreich","class":"amenity","type":"cafe","importance":0.30100000000000005,"icon":"https://nominatim.openstreetmap.org/images/mapicons/food_cafe.p.20.png"}];
}
async function getPlaceDetails(osm_ids) {
  //osm_ids = osm_ids.map(id => (isLetter(id.charAt(0))? id : "N" + id));
  let url = "https://nominatim.openstreetmap.org/lookup.php?format=json&osm_ids=" + osm_ids.join(",");
  console.log(url);
  return await makeApiCall(url);
}

function isLetter(str) {
  return str.length === 1 && str.match(/[a-z]/i);
}

function Sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}
export default DataProcesser;
