import React from 'react';
import Skeleton from 'react-loading-skeleton';
import LoadingListElement from './LoadingListElement.js';
function LoadingList(props) {
  
    var loadingList = [];
 
    for(var i = 0; i < 5; i++)
    loadingList.push(<LoadingListElement/>);

    return (
    loadingList
  );
}

export default LoadingList;
