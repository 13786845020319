import opening_hours from 'opening_hours';

function getOpenState(openingHours) {
  var is_open = false, closes_soon = false;

  var oh;
  
  try {
    oh = new opening_hours(openingHours, { "address": { "country": "Austria", "country_code": "at" } }, { "address": { "country": "Austria", "country_code": "at" } });
    is_open = oh.getState(new Date());
    const myDate = new Date();
    const newDate = new Date(myDate);
    newDate.setHours(newDate.getHours() + 1);

    closes_soon = is_open && !oh.getState(newDate);
    return is_open;
  } catch (e) {


  }

}

export function filter(lokale, filterValues) {


  var savedFavorites = JSON.parse(localStorage.getItem("favorites"));
  var validatedKeys = JSON.parse(localStorage.getItem("validatedKeys"));

  if (filterValues !== null) {
    let { onlyFavorites = false, onlyNotValidated = false, onlyOpened = false, searchTerm = "", category } = filterValues;

    searchTerm = searchTerm ? searchTerm.toLowerCase() : '';
    lokale = lokale && lokale.filter(function (lokal) {

      let categoryFilter = category.includes(lokal.category);
      let openingHours = lokal && lokal.placeDetails[0] && lokal.placeDetails[0].extratags && lokal.placeDetails[0].extratags.opening_hours;
      
      let openedFilter = onlyOpened ? (openingHours === undefined || getOpenState(openingHours)) : true;

      let validatedFilter = onlyNotValidated && validatedKeys ? !validatedKeys.includes(lokal.id) : true;
      let favoritesFilter = onlyFavorites && savedFavorites ? savedFavorites.includes(lokal.id) : true;

      let searchTermFilter = searchTerm && searchTerm.length > 0 ? lokal.title.toLowerCase().indexOf(searchTerm) >= 0 || lokal.description.toLowerCase().indexOf(searchTerm) >= 0 : true;


      return (
        favoritesFilter &&
        validatedFilter &&
        openedFilter &&
        categoryFilter &&
        searchTermFilter
      );
    });
  }
  return lokale;
}


export function loadFilter() {
  return JSON.parse(localStorage.getItem("filterValues"));
}
export function saveFilter(filterValues) {
  localStorage.setItem('filterValues', JSON.stringify(filterValues));
}



