import React from 'react';
import Switch from "react-switch";
import { ESSEN, TRINKEN, FREIZEIT } from '../../utils/constants.js';
import { filter } from '../../utils/filter.js';
import IconFood from '../Icons/IconFood.js';
import IconDrink from '../Icons/IconDrink.js';
import IconFreetime from '../Icons/IconFreetime.js';
import { FaSearch, FaFilter, FaSlidersH } from 'react-icons/fa';
import IconFilter from '../Icons/IconFilter.js';
import {saveFilter} from '../../utils/filter.js';
export default class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValues: this.props.filterValues
    }
  }


  handleSearchTermChange(searchTerm) {
    var { filterValues } = this.state;
    filterValues.searchTerm = searchTerm;
    this.setState(filterValues, () => {
      //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
      this.props.onChange(this.state.filterValues);
      saveFilter(this.state.filterValues);
    });
  }

  
  render() {

    const searchTerm = this.state.filterValues && this.state.filterValues.searchTerm;
    return (
      <div className="shadow mb-3 bg-white rounded">
        <input
          className="form-control p-3 border-0 form-control-lg d-inline input-with-icon"
          type="text"
          onChange={(e) => this.handleSearchTermChange(e.target.value)}
          placeholder="Suche nach z.B. Kaffee" 
          value={searchTerm}
          />
        <button className="btn btn-unstyled d-inline-flex d-sm-none input-icon" onClick={(e) => this.props.toggleMenu(e)}>
          <IconFilter />
        </button>
      </div>
    );
  }
}