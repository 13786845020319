import React from 'react';
import Skeleton from 'react-loading-skeleton';

function LoadingListElement(props) {
  
   return (<tr>
    <td style={{ "width": "40px" }}>
      <Skeleton height={30} width={30} circle={true} />
    </td>
    <td>
      <h1><Skeleton height={30} count={1} /></h1>
      <p><Skeleton height={20} count={2} /></p>
    </td>
    </tr>);

  
}

export default LoadingListElement;
