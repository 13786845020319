import React from 'react';
import {  FaMapMarker } from 'react-icons/fa';

function IconMapMarker(props) {
  
 
  return (
        <FaMapMarker/>
  );
}

export default IconMapMarker;
