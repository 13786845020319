import React from 'react';
import { FaFacebookF } from 'react-icons/fa';

function IconFacebook(props) {
  
 
  return (
        <FaFacebookF/>
  );
}

export default IconFacebook;
