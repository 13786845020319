import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { url_orte } from '../../utils/urls';
import IconFavorite from '../../common/Icons/IconFavorite';
import createUrl, { toggleFavorite, toggleValidate, isValidated, isFavorized } from '../../utils/utils.js';
import { ESSEN, TRINKEN, FREIZEIT } from '../../utils/constants.js';
import { getDistanceFromLatLonInKm, formatDistance } from '../../utils/geo.js';
import { loadFilter } from '../../utils/filter.js';

class Favorizer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFavorite: false
    };
    this.handleFavorize = this.handleFavorize.bind(this);
  }



  handleFavorize(id) {
    toggleFavorite(id);
    this.setState({
      isFavorite: isFavorized(id)
    }, () => {
      this.props.onChange && 
      this.props.onChange(loadFilter());
    });
  }

  render() {

    const { id } = { ...this.props.lokal };

    return (
      <span onClick={() => this.handleFavorize(id)}>
        <IconFavorite active={isFavorized(id) ? "active" : ""} />
      </span>
    );
  }

}

export default Favorizer;
