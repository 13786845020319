export function getDistanceFromLatLonInKm(position1, position2) {

  var lat1 = position1[0],
    lon1 = position1[1],
    lat2 = position2[0],
    lon2 = position2[1];
  
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km

  return d;
}


export function formatDistance(km) {
  let roundedDistanceInMeter = (Math.round(km * 10) / 10 * 1000);
  if(roundedDistanceInMeter < 1000) {
    return roundedDistanceInMeter + " m";
  } else if(roundedDistanceInMeter < 100) {
    return roundedDistanceInMeter/100 +" km";   
  } else if (roundedDistanceInMeter <10000) {
    return Math.round(roundedDistanceInMeter/100)/10 +" km";
  }
  return Math.round(roundedDistanceInMeter/1000) +" km";
  
 
  
}
function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
