import React from 'react';
import { FaFutbol } from 'react-icons/fa';

function IconFreetime(props) {
  
 
  return (
        <FaFutbol/>
  );
}

export default IconFreetime;
