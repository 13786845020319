export const ESSEN = "Essen";
export const TRINKEN = "Trinken";
export const FREIZEIT = "Freizeit";
export const CITIES = {
  wien: {
    name: "Wien",
    latLon: [48.205, 16.36]
  },
  berlin: {
    name: "Berlin",
    latLon: [52.52, 13.40]
  }
};