import React from 'react';
import { FaMap } from 'react-icons/fa';

function IconMap(props) {
  
 
  return (
        <FaMap/>
  );
}

export default IconMap;
