import opening_hours from 'opening_hours';

export default function createUrl(text) {
  return text.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss').replace(/ /g, '-').replace(/é/g, 'e');
}

export function validate(lokalId) {
  var validatedKeys = [];
  var savedKeys = JSON.parse(localStorage.getItem("validatedKeys"));
  if(savedKeys !== null) {
    validatedKeys = savedKeys;
  }
  validatedKeys.push(lokalId);
  localStorage.setItem('validatedKeys', JSON.stringify(validatedKeys));
}

export function isValidated(lokalId) {
  var savedKeys = JSON.parse(localStorage.getItem("validatedKeys"));

  if(savedKeys === null) {
    return false;
  }
  return savedKeys.includes(lokalId);
}


export function isFavorized(lokalId) {
  var savedFavorites = JSON.parse(localStorage.getItem("favorites"));
  if(savedFavorites === null) {
    return false;
  }
  
  return savedFavorites.includes(lokalId);
}

export function isOpen(lokal) {
  var is_open = undefined;
  lokal.placeDetails.forEach(function(detail) {
    if(detail.extratags.opening_hours) {
        var oh = new opening_hours(detail.extratags.opening_hours, detail, { 'locale': 'at' });
        is_open = is_open || oh.getState(new Date());
      
    }

  });
  
  
  return is_open; 
}

export function toggleFavorite(lokalId) {
  var favorites = [];
  var savedFavorites = JSON.parse(localStorage.getItem("favorites"));
  if(savedFavorites !== null) {
    favorites = savedFavorites;
  }
  if(!isFavorized(lokalId)) {
    favorites.push(lokalId);
  } else {
    favorites.splice(favorites.indexOf(lokalId), 1);
  }
  
  localStorage.setItem('favorites', JSON.stringify(favorites));
}


export function toggleValidate(lokalId) {
  var validatedKeys = [];
  var savedvalidatedKeys = JSON.parse(localStorage.getItem("validatedKeys"));
  if(savedvalidatedKeys !== null) {
    validatedKeys = savedvalidatedKeys;
  }
  if(!isValidated(lokalId)) {
    validatedKeys.push(lokalId);
  } else {
    validatedKeys.splice(validatedKeys.indexOf(lokalId), 1);
  }
  
  localStorage.setItem('validatedKeys', JSON.stringify(validatedKeys));
}



export function getLokale() {
  return JSON.parse(localStorage.getItem("lokale"));
}



export function getLokaleSaveDate() {
  return JSON.parse(localStorage.getItem("lokaleSaveDate"));
}



export function saveLokale(lokale) {
 
    localStorage.setItem('lokale', JSON.stringify(lokale));
    localStorage.setItem('lokaleSaveDate', new Date().toISOString().slice(0,10))  ;
  
}

export function getBookmarks() {
  return JSON.parse(localStorage.getItem("bookmarks"));
}

export function saveBookmarks(bookmarkName) {
  var savedBookmarks = getBookmarks() || [];
  
  savedBookmarks.push({"name" : bookmarkName});
    localStorage.setItem('bookmarks', JSON.stringify(savedBookmarks));
  
}