import React from 'react';
import {  FaSlidersH } from 'react-icons/fa';

function IconFilter(props) {
  
 
  return (
        <FaSlidersH/>
  );
}

export default IconFilter;
