import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams
} from "react-router-dom";
import './style/luups-style.scss';
import IconMap from './common/Icons/IconMap.js';
import IconList from './common/Icons/IconList.js';
import { url_orte, url_karte } from './utils/urls.js';
function Navigation() {
  return (
    <nav className="navbar navbar-expand fixed-bottom navbar-dark bg-dark">
          <ul className="nav navbar-nav w-100 mr-auto mt-lg-0">

            <li className="nav-item w-50 text-center">
              <Link to={`.${url_orte}`} className={`nav-link ${document.location.href.includes(url_orte) ? 'active' : ''}`}>
                <IconList /><br/>
                <small>Liste</small>
                </Link>
            </li>
            <li className="nav-item w-50 text-center">
              <Link to={`.${url_karte}`} className={`nav-link ${document.location.href.includes(url_karte) ? 'active' : ''}`}>
              <IconMap />
              <br/>
              <small>
                Karte</small>
                </Link>
            </li>
          </ul>
        </nav>
  );
}

export default Navigation;
