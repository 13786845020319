import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

function IconExternal(props) {
  
 
  return (
        <FaExternalLinkAlt />
  );
}

export default IconExternal;
