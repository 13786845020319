import React from 'react';
import { getBookmarks, saveBookmarks } from '../../utils/utils.js';

class BookmarkSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = { bookmarks: [], 
    bookmarkText: ""};
    this.saveBookmark = this.saveBookmark.bind(this);
    this.setBookmarkText = this.setBookmarkText.bind(this);
  }

  componentWillMount() {
    var bookmarks = getBookmarks();
    this.setState({
      bookmarks
    });
  }

  
  setBookmarkText(bookmarkText) {
    this.setState({
      bookmarkText
    })
  }
  saveBookmark(e) {
    e.preventDefault();
    this.props.onChange(this.state.bookmarkText);
  }

  render() {
    return (
      <form onSubmit={(e) => this.saveBookmark(e)}>
          <input type='text' value={this.state.bookmarkText} onChange={(e) => this.setBookmarkText(e.target.value)}/>
          <button>Speichern</button>
      </form>
    );
  }

}

export default BookmarkSelection;
