import React from 'react';
import { FaRegClock } from 'react-icons/fa';

function IconClock(props) {
  
 
  return (
        <FaRegClock icon={'far'}/>
  );
}

export default IconClock;
