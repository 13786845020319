import React from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

function IconFavorite(props) {
  
 
  return (
    props.active === "active" ? 
    <span class="icon-favorite-active"><FaHeart /></span>
        :
    <span className="icon-favorite-inactive"><FaRegHeart /></span>
  );
}

export default IconFavorite;
