import React from 'react';
import { FaWineGlassAlt } from 'react-icons/fa';

function IconDrink(props) {
   return (
        <FaWineGlassAlt/>
  );
}

export default IconDrink;
