import React from 'react';
import {  FaPhone } from 'react-icons/fa';

function IconPhone(props) {
  
 
  return (
        <FaPhone/>
  );
}

export default IconPhone;
