import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Linkify from 'react-linkify';

import Place from '../Home/Place.js';
import { Link, useParams, Redirect, withRouter } from 'react-router-dom';
import createUrl from '../utils/utils.js';

import IconClock from '../common/Icons/IconClock.js';
import IconMail from '../common/Icons/IconMail.js';
import IconFacebook from '../common/Icons/IconFacebook.js';
import IconFood from '../common/Icons/IconFood.js';
import makeApiCall from '../utils/makeApiCall.js';
import IconPhone from '../common/Icons/IconPhone.js';
import IconMapMarker from '../common/Icons/IconMapMarker.js';
import IconWeb from '../common/Icons/IconWeb.js';
import { isValidated, validate } from '../utils/utils.js';
import IconWheelChair from '../common/Icons/IconWheelChair.js';
import IconFavorite from '../common/Icons/IconFavorite.js';
import { toggleFavorite, isFavorized } from '../utils/utils.js';
import OpeningHours from './OpeningHours.js';
import opening_hours from 'opening_hours';

import Map from '../Karte/Map.js';
import { CITIES } from '../utils/constants.js';
import { url_orte } from '../utils/urls.js';
import logo from '../../resources/pixabay_logo.svg';
import SocialMediaShare from './SocialMediaShare.js';
import { FaChevronLeft } from 'react-icons/fa';
import Favorizer from '../common/Favorizer/Favorizer.js';

import IconExternal from '../common/Icons/IconExternal.js';

class PlaceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLokal: null,
      bgImage: null
    };
  }

  componentWillMount() {
    let { city, year, id } = this.props.match.params;

    makeApiCall(`https://luups.zeitenspruenge.at/api/index.php?city=${city}`).then(
      (lokale) => {
        this.setState({
          currentLokal: lokale.filter(lokal => createUrl(lokal.title) === id),
        }, () => {

          this.getImage();
        });

      }


    );
  }

  getPrettifiedValue(openingHours) {
    var oh = new opening_hours(openingHours, { "address": { "country": "Austria", "country_code": "at" } }, { locale: "de" });


    return oh.prettifyValue({
      conf: {
        locale: 'de',
        block_sep_string: '\n', print_semicolon: false
      },
    });


  }

  getImage() {
    var key = "14569661-0f663382ee15ccd7f5a2c6b29";
    const { currentLokal } = this.state;
    let searchTerm = "bar";
    const searchTerms = ["bagel", "waffel", "porrige", "whisky", "billard", "bike", "jazz", "kabarett", "fuß", "fotoshooting", "cocktail", "weingut", "spritzer", "bakery", "wein", "tea", "tee", "brau", "gasthaus", "zuppa", "museum", "donau", "kino", "yoga", "getränke", "hundertwasser", "theater", "keramik", "schoko", "therme", "riesenrad", "shake", "deli", "coffee", "kaffee", "kuchen", "quiche", "frühstück", "ramen", "wiener", "wrap", "waffle", "speck", "fisch", "fish", "hauptgericht", "curry", "cake", "beer"];

    searchTerms.forEach((st) => {
      if (currentLokal[0].title.toLowerCase().includes(st) || currentLokal[0].description.toLowerCase().includes(st)) {
        if (searchTerm === "bar") {
          searchTerm = st;
        }
      }
    });
    makeApiCall("https://pixabay.com/api/?q=" + searchTerm + "&key=" + key).then(
      (result) => {
        var image = result.hits[0];
        this.setState({
          bgImage: image.largeImageURL
        });
      }
    );

  }





  render() {
    let { city, year, id } = this.props.match.params;
    let { currentLokal, bgImage } = this.state;


    if (currentLokal && currentLokal.length === 0) {
      return <Redirect to="/notfound" />;
    }
    var placeDetails = currentLokal && currentLokal.length > 0 && currentLokal[0].placeDetails;
    var center = placeDetails && placeDetails.length > 0 && placeDetails[0].lat && [placeDetails[0].lat, placeDetails[0].lon];
    var that = this;

    return (
      currentLokal &&
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{currentLokal[0].title} | Luups {CITIES[city].name} {year}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6" >

                <div className="bg-cover" style={{ "backgroundImage": `url(${bgImage})` }}>
                  <div className="d-flex justify-content-between mt-2 lead">
                    <div>
                      <Link to={`/${city}/${year}${url_orte}#${currentLokal[0].id}`} className="text-white">
                        <FaChevronLeft />
                      </Link>
                    </div>
                    <div className="d-flex  justify-content-end mr-3">
                      <SocialMediaShare text={`Schauen wir ins '${currentLokal[0].title}'? ${currentLokal[0].description} (mit Luups-Gutschein) 🙂`} />
                      <div className="ml-2">
                        <Favorizer lokal={currentLokal[0]} />

                      </div>
                    </div>
                  </div>

                  <div className="text d-flex">
                    <h1>{currentLokal[0].title} </h1>
                  </div>
                </div>

                <div className="">
                  <p className="m-2 mr-2 lead d-inline-block d-md-block">{currentLokal[0].description} </p>


                  <div className="d-inline-block d-md-block my-3">
                    {
                      isValidated(currentLokal[0].id) ? 'eingelöst' :
                        <button className="btn btn-primary " onClick={() => validate(currentLokal[0].id)}>
                          einlösen
                      </button>

                    }
                  </div>
                </div>


                <div className="row">
                  {
                    placeDetails.map(function (detail) {

                      let showElement = true;
                      if (currentLokal[0].hasOwnProperty("osm_ids") && currentLokal[0].osm_ids.filter((osm_id) => (osm_id.includes(detail.osm_id))).length === 0) {
                        showElement = false;
                      }


                      let streetname = detail.address.road || detail.address.pedestrian || detail.address.cycleway;

                      return (
                        showElement &&
                        <div className="col-12">
                          <div>
                            <div className="place-label">
                              <IconMapMarker />
                            </div>
                            <address className="place-meta-information">
                              {detail.address.road && detail.address.road}
                              {detail.address.pedestrian && detail.address.pedestrian}
                              {detail.address.cycleway && detail.address.cycleway}
                              {` ${detail.address.house_number} `} <br />
                              {`${detail.address.postcode} ${detail.address.state} `}
                              <div>
                                <a target="_blank" rel="noreferrer noopener" href={`http://maps.google.com/maps?daddr=${streetname} ${detail.address.house_number} ${detail.address.postcode} ${detail.address.state}`}>Route auf Google Maps <IconExternal /></a>
                              </div>

                              <div className="d-md-none ">
                                <div className="map-small">
                                  <Map filteredLokale={currentLokal} center={center} />
                                </div>
                              </div>

                            </address>



                          </div>

                          {
                            detail.extratags && detail.extratags.opening_hours &&
                            <div>
                              <div className="place-label">
                                <IconClock />
                              </div>
                              <div className="place-meta-information">

                                {
                                  that.getPrettifiedValue(detail.extratags.opening_hours)
                                }{' '}
                                <p>
                                  <small>
                                    {detail.extratags.opening_hours && <OpeningHours openingHours={detail.extratags.opening_hours} />}
                                  </small>
                                </p>
                              </div>
                            </div>
                          }



                          {
                            detail.extratags && detail.extratags.website &&
                            <div>
                              <div className="place-label">
                                <IconWeb />
                              </div>
                              <div className="place-meta-information">
                                <p>
                                  <a href={detail.extratags.website} target='_blank' rel='noreferrer noopener'>
                                    {detail.extratags.website}
                                  </a>
                                </p>
                              </div>
                            </div>
                          }

                          {
                            detail.extratags && detail.extratags["contact:website"] &&
                            <div>
                              <div className="place-label">
                                <IconWeb />
                              </div>
                              <div className="place-meta-information">
                                <p>
                                  <a href={detail.extratags["contact:website"]} target='_blank' rel='noreferrer noopener'>
                                    {detail.extratags["contact:website"]}
                                  </a>
                                </p>
                              </div>
                            </div>
                          }

                          {
                            detail.extratags && detail.extratags["contact:facebook"] &&

                            <div>
                              <div className="place-label">
                                <IconFacebook />
                              </div>

                              <div className="place-meta-information">
                                <p>
                                  <a href={detail.extratags["contact:facebook"]} target='_blank' rel='noreferrer noopener'>
                                    {detail.extratags["contact:facebook"]}
                                  </a>
                                </p>
                              </div>
                            </div>


                          }
                          {
                            detail.extratags && detail.extratags["contact:email"] &&
                            <div>
                              <div className="place-label">
                                <IconMail />
                              </div>
                              <div className="place-meta-information">
                                <p>
                                  <a href={`mailto:${detail.extratags["contact:email"]}`}>{detail.extratags["contact:email"]}</a>
                                </p>
                              </div>
                            </div>
                          }

                          {
                            detail.extratags && detail.extratags.phone &&
                            <div>
                              <div className="place-label">
                                <IconPhone />
                              </div>
                              <div className="place-meta-information">
                                <p>
                                  <a href={`tel:${detail.extratags.phone}`}>

                                    {detail.extratags.phone}
                                  </a>
                                </p>
                              </div>
                            </div>
                          }
                          {
                            detail.extratags && detail.extratags.wheelchair &&
                            <div>
                              <div className="place-label">
                                <IconWheelChair />
                              </div>
                              <div className="place-meta-information">
                                <p>
                                  {detail.extratags.wheelchair === "yes" && 'rollstuhltauglich'}
                                  {detail.extratags.wheelchair === "limited" && 'teilweise rollstuhltauglich'}
                                  {detail.extratags.wheelchair === "no" && 'nicht rollstuhltauglich'}
                                </p>
                              </div>
                            </div>
                          }

                          {
                            detail.extratags &&
                            <div>
                              <p>{detail.extratags.cusine}</p>
                              <p>{detail.extratags.sport}</p>
                            </div>
                          }
                          <p>{detail.internet_access}</p>

                          {/* <span className="badge badge-primary">{detail.type.toUpperCase()}</span> */}

                          <Helmet>
                            <meta name="description" content={`${detail.type} in ${currentLokal[0].placeDetails[0].address.postcode} ${CITIES[city]} -  ${currentLokal[0].description} }`} />
                          </Helmet>




                        </div>
                      );
                    })
                  }


                </div>


                <p className="mt-2">
                  <small>Alle Angaben ohne Gewähr</small>

                  <span className="float-md-right mb-0 d-md-none">
                    <small>
                      <a href="https://pixabay.com/">
                        Symbolfoto von <img src={logo} alt="Pixabay " width="70px" />
                      </a>
                    </small>
                  </span>

                </p>
              </div>
              <div className="d-none d-md-block col-12 col-md-6 col-xl-6">
                <div className="sticky-top">
                  <Map filteredLokale={currentLokal} center={center} />
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

export default withRouter(PlaceView);
