import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  
} from "react-router-dom";
import { matchPath } from 'react-router'
import './style/luups-style.scss';
import Home from './Home/Home.js';
import NotFound from './NotFound/NotFound.js';
import PlaceView from './PlaceView/PlaceView.js';
import Karte from './Karte/Karte.js';
import Navigation from './Navigation.js';
import DataProcesser from './DataProcesser/DataProcesser.js';
import '../../node_modules/leaflet/dist/leaflet.css';
import { url_orte, url_karte } from './utils/urls.js';
import { ESSEN, TRINKEN, FREIZEIT, CITIES } from './utils/constants.js';
import {loadFilter} from './utils/filter.js';
import makeApiCall from './utils/makeApiCall.js';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      filterValues: {
        onlyFavorites: false,
        onlyNotValidated: false,
        onlyOpened: false,
        searchTerm: '',
        category: [ESSEN, TRINKEN, FREIZEIT]
      }
    }
  }
  componentWillMount() {
    var filterValues = loadFilter();
   
    if (filterValues !== null) {
      this.setState({filterValues});
    }

    makeApiCall(`https://luups.zeitenspruenge.at/api/index.php?city=wien`).then(
      (lokale) => {
        this.setState({
           lokale
        });
      }
    );

  }
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact  path="/data/" children={<DataProcesser />} />
            
            <Route exact path={`/:city/:year${url_orte}/:id`} children={<PlaceView lokale={this.state.lokale} />} />
            <Route exact path={`/:city/:year${url_orte}`} children={<Home filterValues={this.state.filterValues} />} />
            <Route exact path={`/:city/:year${url_karte}`} children={<Karte filterValues={this.state.filterValues} />} />

            <Route exact path="/">
              <Redirect to={`/wien/2020${url_orte}`} />
            </Route>
            
            <Route path="/notfound" exact={true}  component={NotFound} />
            <Route component={NotFound} />
            
          </Switch>
          <div className="d-md-none">
            <Navigation />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
