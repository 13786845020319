import React from 'react';
import opening_hours from 'opening_hours';

export default function OpeningHours(props) {
  var is_open = false, closes_soon = false;
  var { openingHours , detail} = props;

  var oh;
  try {
    oh = new opening_hours(openingHours, {"address": {"country":"Austria","country_code":"at"}}, {"address": {"country":"Austria","country_code":"at"}});
    is_open = oh.getState(new Date());
    const myDate = new Date();
    const newDate = new Date(myDate);
    newDate.setHours(newDate.getHours() + 1);
  
    closes_soon = is_open && !oh.getState(newDate);
  
  } catch(e) {
    
    console.log(e);
  }
  


  if(is_open && !closes_soon) {
    return (<span className='text-success'>geöffnet</span>);
  }
  
  if(is_open && closes_soon ) {
    return (<span className='text-warning'>schließt bald</span>);
  }
  
  if( !is_open ) {
    return (<span className='text-danger'>geschlossen</span>);
  }
  return null;
}