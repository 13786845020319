import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

function IconMail(props) {
  
 
  return (
        <FaEnvelope/>
  );
}

export default IconMail;
