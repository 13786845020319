import React from 'react';
import BookmarkInput from './BookmarkInput';
import { getBookmarks, saveBookmarks } from '../../utils/utils.js';

class BookmarkSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = { bookmarks: [] };
    this.handleBookmarkChange = this.handleBookmarkChange.bind(this);
  }

  componentWillMount() {
    var bookmarks = getBookmarks();
    this.setState({
      bookmarks
    });
  }

  renderBookmarks() {
    return this.state.bookmarks && this.state.bookmarks.map(function(bookmark) {
      return (
        
        <li>
          <label>
            <input type='checkbox' />
            {bookmark.name}
            </label>
        </li>
      );
    });
  }
  handleBookmarkChange(bookmarkName) {
    saveBookmarks(bookmarkName);
    this.setState({
      bookmarks: getBookmarks()
    })
  }

  render() {
    return (
      <div>
        <ul>
          <li><BookmarkInput onChange={this.handleBookmarkChange} /></li>
          {this.renderBookmarks()}
        </ul>
      </div>
    );
  }

}

export default BookmarkSelection;
