import React from 'react';
import Switch from "react-switch";
import { ESSEN, TRINKEN, FREIZEIT } from '../../utils/constants.js';
import { filter, saveFilter } from '../../utils/filter.js';
import IconFood from '../Icons/IconFood.js';
import IconDrink from '../Icons/IconDrink.js';
import IconFreetime from '../Icons/IconFreetime.js';
import SearchBox from './SearchBox.js';
import CategoryFilter from './CategoryFilter.js';

export default class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValues: this.props.filterValues
    }
  }

  handleSearchTermChange(searchTerm) {
    var { filterValues } = this.state;
    filterValues.searchTerm = searchTerm;
    this.setState(filterValues, () => {
      //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
      this.props.onChange(this.state.filterValues);
      saveFilter(filterValues);
    });
  }

  handleFavoriteChange() {
    let filterValues = this.state.filterValues;
    filterValues.onlyFavorites = !filterValues.onlyFavorites;
    this.setState(
      filterValues
      , () => {
        //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
        this.props.onChange(this.state.filterValues);
        saveFilter(filterValues);
      });
  }

  handleValidatedChange() {
    let filterValues = this.state.filterValues;
    filterValues.onlyNotValidated = !filterValues.onlyNotValidated;
    this.setState(
      filterValues
      , () => {
        //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
        this.props.onChange(this.state.filterValues);
        saveFilter(filterValues);
      });
  }

  handleOpenedChange() {
    let filterValues = this.state.filterValues;
    filterValues.onlyOpened = !filterValues.onlyOpened;
    this.setState(
      filterValues
      , () => {
        //this.props.onChange(filter(this.props.lokale, this.state.filterValues));
        this.props.onChange(this.state.filterValues);
        saveFilter(filterValues);
      });
  }

  componentDidMount() {
  //  this.props.onChange(filter(this.props.lokale, this.state.filterValues));

    this.props.onChange(this.state.filterValues);
  }

  render() {
    return (
      <form className="">
        <div className="form-group d-lg-none">
          <CategoryFilter filterValues={this.state.filterValues} lokale={this.props.lokale} onChange={this.props.onChange.bind(this)} />
        </div>

        <div className="form-group">
          <label className="d-flex align-items-center justify-content-between">
            <span className="">Nur Favoriten anzeigen</span>

            <Switch className="float-right" onChange={() => this.handleFavoriteChange()} checked={this.state.filterValues.onlyFavorites}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={30} />
          </label>
        </div>
        <div className="form-group">
          <label className="d-flex align-items-center justify-content-between">
            <span className="">Nur Verfügbare anzeigen</span>
            <Switch className="" onChange={() => this.handleValidatedChange()} checked={this.state.filterValues.onlyNotValidated}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={30} />
          </label>
        </div>

        <div className="form-group">
          <label className="d-flex align-items-center justify-content-between">
            <span className="">Nur offene Orte anzeigen</span>
            <Switch className="" onChange={() => this.handleOpenedChange()} checked={this.state.filterValues.onlyOpened}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={30} />
          </label>
        </div>
      </form>
    );
  }
}