import React from 'react';
import { FaList } from 'react-icons/fa';

function IconList(props) {
  
 
  return (
        <FaList/>
  );
}

export default IconList;
