import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { geolocated } from 'react-geolocated';
import Place from './Place.js';
import { Redirect, withRouter } from 'react-router-dom';
import Filter from '../common/Filter/Filter.js';
import { Helmet } from "react-helmet";
import SearchBox from '../common/Filter/SearchBox.js';
import { ESSEN, TRINKEN, FREIZEIT, CITIES } from '../utils/constants.js';
import CategoryFilter from '../common/Filter/CategoryFilter.js';
import Map from '../Karte/Map.js';
import { getLokale, saveLokale, getLokaleSaveDate } from '../utils/utils.js';
import { getDistanceFromLatLonInKm, formatDistance } from '../utils/geo.js';
import { filter } from '../utils/filter.js';
import { url_orte, url_karte } from '../utils/urls.js';
import makeApiCall from '../utils/makeApiCall.js';
import Skeleton from 'react-loading-skeleton';
import LoadingList from '../common/LoadingList/LoadingList.js';
import Modal from 'react-modal';

Modal.setAppElement('#root');

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lokale: null,
      filteredLokale: null,
      menuOpen: false

    }

    this.update = this.update.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  sort(sortMethod) {
    let { filteredLokale } = this.state;

    var currentPosition = this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords ? [this.props.coords.latitude, this.props.coords.longitude] : null;


    switch (sortMethod) {
      case "desc":
        filteredLokale.sort(function (a, b) {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return -1;

          } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        break;
      case "asc":
      default:
        filteredLokale.sort(function (a, b) {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;

          } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        break;
      case "closest":
        filteredLokale.sort((a, b) => {

          var distanceA = currentPosition && a.placeDetails && a.placeDetails.length > 0 && a.placeDetails[0].lat ? getDistanceFromLatLonInKm(currentPosition, [a.placeDetails[0].lat, a.placeDetails[0].lon]) : null;
          var distanceB = currentPosition && b.placeDetails && b.placeDetails.length > 0 && b.placeDetails[0].lat ? getDistanceFromLatLonInKm(currentPosition, [b.placeDetails[0].lat, b.placeDetails[0].lon]) : null;

          if (distanceA === null || distanceB === null) {
            return 1;
          }
          if (distanceA < distanceB) {
            return -1;

          } else if (distanceA > distanceB) {
            return 1;
          }
          return 0;
        });

        break;


    }




    this.setState(filteredLokale);
  }

  update(filterValues) {
    if (filterValues) {
      this.setState({
        filteredLokale: filter(this.state.lokale, filterValues)
      });
    }

  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  componentWillMount() {
    let { city, year } = this.props.match.params;

    let cachedLokale = JSON.parse(localStorage.getItem("lokale"));
    let cacheDate = JSON.parse(localStorage.getItem("cacheDate"));
    console.log(cacheDate);
    console.log(cacheDate  && Date.now() - cacheDate > 86400);
    if (cachedLokale === null || (cacheDate  && Date.now() - cacheDate > 86400)) {
      makeApiCall(`https://luups.zeitenspruenge.at/api/index.php?city=${city}`).then(
        (lokale) => {
          localStorage.setItem('lokale', JSON.stringify(lokale));
          localStorage.setItem('cacheDate', JSON.stringify(Date.now()));
          
          this.setState({
            lokale,
            filteredLokale: filter(lokale, this.props.filterValues)
          });
        }
      );
    } else {
      this.setState({
        lokale : cachedLokale,
        filteredLokale: filter(cachedLokale, this.props.filterValues)
      });
    }

  }
  gotoCity(city) {
    window.location = `/${city}/${this.props.match.params.year}${url_orte}`;
  }
  render() {
    const { city, year } = this.props.match.params;

    let sum = 0;
    if (this.props.filterValues.onlyFavorites) {
      sum++;
    }

    if (this.props.filterValues.onlyNotValidated) {
      sum++;
    }

    if (this.props.filterValues.onlyOpened) {
      sum++;
    }

    if (!CITIES.hasOwnProperty(city)) {
      console.log(CITIES);
      console.log(city)
      //return <Redirect to="/" />;
    }

    if (year !== "2020") {
      //return <Redirect to="/" />;
    }


    return (
      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Luups {CITIES[city]["name"]} {year}</title>

        </Helmet>
        <div className="d- md-none">
          <Menu className="bg-white p-4" isOpen={this.state.menuOpen}>
            {this.state.lokale &&
              <Filter
                filterValues={this.props.filterValues}
                onChange={this.update.bind(this)}
              />
            }
          </Menu>
        </div>

        <div className="search-box pt-4 pb-3 ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-10">
                <h1 className="h4">Luups {CITIES[city]["name"]} {year}</h1>
                <p>Inoffiziell</p>
              </div>
              <div className="col-6 col-md-2">
                <div>
                  <select
                    className="form-control select-unstyled"
                    onChange={(e) => this.gotoCity(e.target.value)}>
                    <option value="wien" selected={city === "wien"}>Wien</option>
                    <option value="berlin" selected={city === "berlin"}>Berlin</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="container-fluid">
          <div className="row">
            <div cnpm lassName="d-none col-2">
              <div className="sticky-top">
                {this.props.lokale &&
                  <Filter
                    lokale={this.props.lokale}
                    filterValues={this.props.filterValues}
                    onChange={this.update.bind(this)}
                  />
                }
              </div>
            </div>
            <div className="col-12 col-md-8 col-xl-6 border-left">
              <div >
                <div className="sticky-top bg-color">

                  <div className="form-group pt-3 ">
                    <SearchBox
                      filterValues={this.props.filterValues}
                      lokale={this.props.lokale}
                      toggleMenu={this.toggleMenu.bind(this)}
                      onChange={this.update.bind(this)} />
                    <div className="d-none d-sm-block">
                      <div className=" w-75 d-inline-block">
                        <div className="form-group ">
                          <div className="d-flex">
                            <CategoryFilter filterValues={this.props.filterValues} lokale={this.props.lokale} onChange={this.update.bind(this)} />
                            <button type="button" onClick={() => this.toggleMenu()} className={`btn btn-sm  mr-2`}>
                              weitere Filter
                              {
                                sum > 0 &&
                                <span className="ml-1 badge badge-primary">
                                  {sum}
                                </span>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group w-25 d-inline-block">
                        <select onChange={(e) => this.sort(e.target.value)}
                          className="form-control form-control-sm form-control-secondary">
                          <option value="asc">Alphabetisch</option>
                          <option value="closest">Entfernung</option>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>

                <table className="table">

                  {
                    this.state.filteredLokale ?
                      this.state.filteredLokale.map((lokal) =>
                        <Place lokal={lokal} onChange={this.update.bind(this)} />
                      )
                      :
                      <LoadingList count="3" />
                  }
                </table>
              </div>

            </div>

            <div className="d-none d-md-inline-block col-4 col-xl-6">
              <div className="sticky-top">
                {this.state.filteredLokale &&
                  <Map filteredLokale={this.state.filteredLokale} />
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }

}


export default withRouter(geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(Home));