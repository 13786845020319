import React from 'react';
import { FaGlobe } from 'react-icons/fa';

function IconWeb(props) {
  
 
  return (
        <FaGlobe/>
  );
}

export default IconWeb;
