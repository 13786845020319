import React from 'react';
import { FaRegCheckSquare } from 'react-icons/fa';

function IconValidate(props) {
  
 
  return (
    props.active === "active" ? 
    <span class="icon-validate-active"><FaRegCheckSquare /></span>
        :
    <span className="icon-validate-inactive"><FaRegCheckSquare /></span>
  );
}

export default IconValidate;
