import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function IconWhatsapp(props) {
  
 
  return (
        <FaWhatsapp />
  );
}

export default IconWhatsapp;
