import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { url_orte } from '../utils/urls';
import IconFavorite from '../common/Icons/IconFavorite';
import IconFood from '../common/Icons/IconFood';
import IconClock from '../common/Icons/IconClock';
import IconDrink from '../common/Icons/IconDrink';
import IconFreetime from '../common/Icons/IconFreetime';
import createUrl, { toggleFavorite, toggleValidate, isValidated, isFavorized } from '../utils/utils.js';
import { ESSEN, TRINKEN, FREIZEIT } from '../utils/constants.js';
import { getDistanceFromLatLonInKm, formatDistance } from '../utils/geo.js';
import { geolocated } from 'react-geolocated';
import { loadFilter } from '../utils/filter.js';
import Modal from 'react-modal';

import BookmarkSelection from '../common/Bookmark/BookmarkSelection';
import BookmarkInput from '../common/Bookmark/BookmarkInput';
import IconDirection from '../common/Icons/IconDirection';
import IconValidate from '../common/Icons/IconValidate';
import OpeningHours from '../PlaceView/OpeningHours';
import Favorizer from '../common/Favorizer/Favorizer';
class Place extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFavorite: false,
      favorizeDialogOpen: false,
    };
    
    this.handleValidate = this.handleValidate.bind(this);
    this.getMetaInfo = this.getMetaInfo.bind(this);
  }




  handleValidate(id) {
    toggleValidate(id);
    this.setState({
      isValidated: isValidated(id)
    }, () => {
      this.props.onChange(loadFilter());
    });
  }
  getMetaInfo(placeDetails) {

    var retVal = [];
    var currentPosition = this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords ? [this.props.coords.latitude, this.props.coords.longitude] : null;

    return placeDetails.map(function (detail) {
      var distance = currentPosition ? getDistanceFromLatLonInKm(currentPosition, [detail.lat, detail.lon]) : '';

      return (
        <div className="place-info">
          <div className=" d-inline-block" style={{ "width": "150px" }}>
            <span>{`${detail.address.postcode} ${detail.address.state}`}</span>
            <span>{` \u00b7 ${distance && formatDistance(distance)} `}</span>
          </div>
          <div className="d-inline-block">
            {
              detail && detail.extratags && detail.extratags.opening_hours &&
              <span className="ml-2">
                <IconClock />{' '}
                <OpeningHours detail={detail} openingHours={detail.extratags.opening_hours} />
              </span>

            }
          </div>
        </div>
      );
    });
  }

  render() {

    const { city, year } = this.props.match.params;
    const { id, title, description, placeDetails, category } = { ...this.props.lokal };

    let metaInfo = null;

    if (placeDetails && placeDetails.length > 0) {


      metaInfo = this.getMetaInfo(placeDetails);
    }


    let url = createUrl(title);

    return (




      <tr id={id}>
        <td>

          {category === ESSEN ? <span className="marker marker-food"><IconFood /></span> : ''}
          {category === TRINKEN ? <span className="marker marker-drink"><IconDrink /></span> : ''}
          {category === FREIZEIT ? <span className="marker marker-freetime"><IconFreetime /></span> : ''}

        </td>
        <td className={`card-${category}`}>
          <Link to={`/${city}/${year}${url_orte}/${encodeURI(url)}`} className={` ${isValidated(id) ? 'text-muted' : ''}`}>
            <span className="link-title">
              {title}
            </span>

            <p className={`card-text  ${isValidated(id) ? 'text-muted' : 'text-secondary'}`}>
              {description}<br />
              {metaInfo}
              {/* <Modal isOpen={this.state.favorizeDialogOpen} className="modal-dialog">
              <div>Test</div>
            </Modal>

            {/* <BookmarkSelection /> */}

            </p>
          </Link>
        </td>
        <td className="lead">
          <span onClick={() => this.handleValidate(id)}>
            <IconValidate active={isValidated(id) ? "active" : ""} />
          </span>
        </td>
        <td className="lead">
         
          <Favorizer onChange={this.props.onChange.bind(this)} lokal={this.props.lokal} />

        </td>

      </tr>


    );
  }

}

export default withRouter(geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Place));
