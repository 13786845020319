import React from 'react';
import { FaUtensils } from 'react-icons/fa';

function IconFood(props) {
  
 
  return (
        <FaUtensils/>
  );
}

export default IconFood;
