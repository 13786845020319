import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import ReactDOMServer from 'react-dom/server';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { url_orte, url_karte } from '../utils/urls.js';
import createUrl from '../utils/utils.js';
import IconFreetime from '../common/Icons/IconFreetime';
import { geolocated } from 'react-geolocated';
import IconDrink from '../common/Icons/IconDrink.js';
import IconFood from '../common/Icons/IconFood.js';
import Filter from '../common/Filter/Filter.js';
import { ESSEN, TRINKEN, FREIZEIT } from '../utils/constants.js';
import { filter } from '../utils/filter.js';
import makeApiCall from '../utils/makeApiCall.js';
import { Helmet } from 'react-helmet';
import { CITIES } from '../utils/constants.js';
delete L.Icon.Default.prototype._getIconUrl;



L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../resources/marker_64x64.png'),
  iconUrl: require('../../resources/marker_64x64.png'),
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

class Karte extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      lokale: [],
      filteredLokale: []
    };
  }

  update(filteredLokale) {
    this.setState({
      filteredLokale
    });
  }

  componentWillMount() {
    let { city, year } = this.props.match.params;

    makeApiCall(`https://luups.zeitenspruenge.at/api/index.php?city=${city}`).then(
      (lokale) => {
        this.setState({
          lokale,
          filteredLokale: filter(lokale, this.props.filterValues)
        });
      }


    );
  }
  render() {
    const { city, year } = this.props.match.params;
    
    if (!CITIES.hasOwnProperty(city)) {
      return <Redirect to="/" />;
    }

    if (year !== "2020") {
      return <Redirect to="/" />;
    }


    var center = this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords ? [this.props.coords.latitude, this.props.coords.longitude] : [48.205, 16.36];

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={`Luups ${CITIES[city].name} ${year} Übersichtskarte - Alle Orte auf einem Blick`} />
          <title>{`Luups ${CITIES[city].name} ${year} Übersichtskarte`}</title>
        </Helmet>
        <Menu>

          <Filter
            lokale={this.props.lokale}
            filterValues={this.props.filterValues}
            onChange={this.update.bind(this)}
          />

        </Menu>
        <div style={{ "height": "100vh", "width": "100vw" }}>

          <LeafletMap
            center={center}
            zoom={14}
            maxZoom={18}
            attributionControl={true}
            zoomControl={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            dragging={true}
            animate={true}
            boundsOptions={{ padding: [50, 50] }}
            easeLinearity={0.35}
          >

            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
            //url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'


            />

            {
              this.props.isGeolocationAvailable &&
              this.props.isGeolocationEnabled &&
              this.props.coords &&
              <Marker position={[this.props.coords.latitude, this.props.coords.longitude]}>
              </Marker>
            }
            {

              this.state.filteredLokale &&
              this.state.filteredLokale.length > 0 &&
              this.state.filteredLokale.map(lokal => (
                lokal.latitude && (
                  <Marker key={lokal.title} icon={getIcon(lokal.category)} position={[lokal.latitude, lokal.longitude]}>
                    <Popup>
                      <Link to={`/${city}/${year}${url_orte}/${encodeURI(createUrl(lokal.title))}`}>
                        <h4>{lokal.title}</h4>
                        <p>{lokal.description}</p>
                      </Link>

                    </Popup>
                  </Marker>
                )

              )
              )
            }

          </LeafletMap>
        </div>
      </div>
    );
  }


}


function getIcon(category) {
  var iconFood = L.divIcon({
    iconSize: null,
    className: 'marker marker-food',
    html: ReactDOMServer.renderToString(<IconFood />)
  });

  var iconDrink = L.divIcon({
    iconSize: null,
    className: 'marker marker-drink',
    html: ReactDOMServer.renderToString(<IconDrink />)
  });

  var iconFreetime = L.divIcon({
    iconSize: null,
    className: 'marker marker-freetime',
    html: ReactDOMServer.renderToString(<IconFreetime />)
  });


  if (category === "Essen") {
    return iconFood;
  }
  if (category === "Trinken") {
    return iconDrink;
  }

  return iconFreetime;

}
export default withRouter(geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Karte));