import React, { createRef } from 'react';
import { slide as Menu } from 'react-burger-menu';
import ReactDOMServer from 'react-dom/server';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Map as LeafletMap, TileLayer, Marker, Popup, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { url_orte, url_karte } from '../utils/urls.js';
import createUrl from '../utils/utils.js';
import IconFreetime from '../common/Icons/IconFreetime';
import { geolocated } from 'react-geolocated';
import IconDrink from '../common/Icons/IconDrink.js';
import IconFood from '../common/Icons/IconFood.js';
import { ESSEN, TRINKEN, FREIZEIT, CITIES } from '../utils/constants.js';
import { getDistanceFromLatLonInKm, formatDistance } from '../utils/geo.js';

import equal from 'fast-deep-equal';
delete L.Icon.Default.prototype._getIconUrl;



L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../resources/marker_64x64.png'),
  iconUrl: require('../../resources/marker_64x64.png'),
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [32, 32],
  iconAnchor: [16, 16],
});

class Map extends React.Component {


  constructor(props) {
    super(props);
    this.state = { filteredLokale: props.filteredLokale };
    this.fitToBounds = this.fitToBounds.bind(this);
    this.mapRef = createRef();
    this.groupRef = createRef();
  }

  update(filteredLokale) {
    this.setState({
      filteredLokale
    });
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.filteredLokale, prevProps.filteredLokale)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.update(this.props.filteredLokale);
      this.fitToBounds();
    }
  }
  fitToBounds() {
    const map = this.mapRef.current.leafletElement;  //get native Map instance
    const group = this.groupRef.current.leafletElement; //get native featureGroup instance
    const bounds = group.getBounds();
    if (Object.keys(bounds).length > 0) {
      map.fitBounds(group.getBounds());
    }

  }


  render() {
    const { city, year } = this.props.match.params;
    /*
        if (city !== "wien") {
          return <Redirect to="/" />;
        }
    
        if (year !== "2020") {
          return <Redirect to="/" />;
        }
    */

    var center = this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords ? [
        this.props.coords.latitude, this.props.coords.longitude] : CITIES[city].latLon;


    if (getDistanceFromLatLonInKm(CITIES[city].latLon, center) > 100) {
      center = CITIES[city].latLon;
    }
    if (this.props.center) {
      center = this.props.center;
    }


    return (

      <div>
        {/* <button onClick={this.fitToBounds}>Zoom</button> */}
        <LeafletMap
          center={center}
          zoom={14}
          maxZoom={18}
          ref={this.mapRef}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
        >

          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          //url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          />

          {
            this.props.isGeolocationAvailable &&
            this.props.isGeolocationEnabled &&
            this.props.coords &&
            <Marker position={[this.props.coords.latitude, this.props.coords.longitude]}></Marker>
          }
          <FeatureGroup ref={this.groupRef}>
            {

              this.state.filteredLokale && this.state.filteredLokale.map(lokal => (
                lokal.placeDetails.map(detail => (
                  detail.lat && (
                    <Marker key={lokal.title} icon={getIcon(lokal.category)} position={[detail.lat, detail.lon]}>
                      <Popup>
                        <Link to={`/${city}/${year}${url_orte}/${encodeURI(createUrl(lokal.title))}`}>
                          <h6>{lokal.title}</h6>
                          <p>{lokal.description}</p>
                          <p>{lokal.category}</p>
                        </Link>

                      </Popup>
                    </Marker>
                  )
                ))
              ))
            }
          </FeatureGroup>

        </LeafletMap>

      </div>

    );
  }


}


function getIcon(category) {
  var iconFood = L.divIcon({
    iconSize: null,
    className: 'marker marker-food',
    html: ReactDOMServer.renderToString(<IconFood />)
  });

  var iconDrink = L.divIcon({
    iconSize: null,
    className: 'marker marker-drink',
    html: ReactDOMServer.renderToString(<IconDrink />)
  });

  var iconFreetime = L.divIcon({
    iconSize: null,
    className: 'marker marker-freetime',
    html: ReactDOMServer.renderToString(<IconFreetime />)
  });


  if (category === "Essen") {
    return iconFood;
  }
  if (category === "Trinken") {
    return iconDrink;
  }

  return iconFreetime;

}
export default withRouter(geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  watchPosition: true,
  userDecisionTimeout: null,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  isOptimisticGeolocationEnabled: true

})(Map));